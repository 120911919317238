import { AppBar, AppBarProps, Box } from '@material-ui/core';
import { MenuBookIcon, SwapVertIcon, WalletIcon } from '@tradener/lumen';

import useRule from '~/hooks/useRule';
import { Logo } from '~/theme/components';

import NavItem from './NavItem';
import Profile from './Profile';
import useStyles from './styles';

const LeftSideBar: React.ComponentType<AppBarProps> = (props) => {
  const classes = useStyles();
  const [Book] = useRule(NavItem);
  const [Portfolio] = useRule(NavItem);
  const [Trading] = useRule(NavItem);

  return (
    <AppBar classes={classes} elevation={0} position="fixed" {...props}>
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" gridRowGap="0.75rem">
        <Logo display="flex" justifyContent="center" alignItems="center" width="56px" height="56px" />
        <Book to="/book/list" icon={MenuBookIcon} rule="MESA" />
        <Portfolio to="/portfolio/table" icon={WalletIcon} rule="SIMULATION" />
        <Trading to="/trading/list" icon={SwapVertIcon} rule="TRADING" />
      </Box>
      <Profile />
    </AppBar>
  );
};

export default LeftSideBar;
