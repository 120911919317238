import { toast } from 'react-toastify';

import JwtDecode from 'jwt-decode';
import type { Action, Reducer } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import type { ValuesType } from 'utility-types';

import http from '~/services/http';
import { dispatchToHttp } from '~/services/sessionAlive';
import { getTenants } from '~/utils/getTenants';
import { getTradingUsers } from '~/utils/getTradingUsers';
import state from '~/utils/state';

export enum ActionTypes {
  SIGN_IN = 'AUTH/SIGN_IN_REQUEST',
  SIGN_IN_APP = 'AUTH/SIGN_IN_APP_REQUEST',
  SIGN_IN_SUCCESS = 'AUTH/SIGN_IN_SUCCESS',
  SIGN_OUT = 'AUTH/SIGN_OUT',
  REFRESH_TOKEN = 'AUTH/REFRESH_TOKEN',
  PROFILE_ADDITION = 'AUTH/PROFILE_ADDITION',
}

export enum Permissions {
  MESA = 'quote:read',
  PORTFOLIO = 'simulation:all',
  SIMULATION = 'simulation:all',
  SIMULATION_INTEREST_RATE = 'simulation:present_value',
  SIMULATION_EXPORT_RESULT = 'simulation:export_result',
  SIMULATION_EXPORT_LIST_CONTRACT = 'simulation:export_list_contract',
  REQUEST_MOVE = 'trading:request_move',
  TRADING = 'trading:read',
  APPROVE_EXPIRED = 'quote:approve_expired',
  TRADING_DASHBOARD = 'trading:dashboard',
  TRADING_ANALYTICS = 'bbce_products:analytics',
}

export enum RolesTypes {}

export interface ProfileProps {
  googleId: string;
  imageUrl: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
}

export interface AuthenticationProps {
  token: string;
  refresh_token: string;
}

export interface SessionProps {
  token: string | undefined;
  refreshToken: string | undefined;
}

export interface SignInAppDataProps {
  client_app_key: string;
  client_secret_key: string;
}

export interface AuthorizationDataProps extends AuthenticationProps {
  permissions: Permissions[];
}

export interface SignInRequestProps {
  accessToken: string;
  profile?: ProfileProps;
}

export interface SignInSuccessDataProps extends AuthenticationProps {
  profile?: ProfileProps;
  authorization: AuthorizationDataProps;
}

export interface StateProps extends SessionProps {
  profile: ProfileProps | null;
  permissions: Permissions[];
  roles: RolesTypes[];
  id?: string;
}

export interface ActionProps extends Action<ActionTypes> {
  value?: ValuesType<StateProps>;
}

export const initialState: StateProps = {
  token: undefined,
  refreshToken: undefined,
  profile: null,
  permissions: [],
  roles: [],
};

const reducer: Reducer<StateProps, ActionProps> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PROFILE_ADDITION:
      return { ...state, profile: action.value as ProfileProps };
    case ActionTypes.REFRESH_TOKEN:
    case ActionTypes.SIGN_IN_SUCCESS:
      return { ...state, ...(action.value as unknown as StateProps) };
    case ActionTypes.SIGN_OUT:
      return initialState;
    default:
      return state;
  }
};

export const actions = {
  signInAppRequest: (value: SignInAppDataProps) => ({ type: ActionTypes.SIGN_IN_APP, value }),
  signInRequest: (value: SignInRequestProps) => ({ type: ActionTypes.SIGN_IN, value }),
  signInSuccess: (value: SignInSuccessDataProps) => ({ type: ActionTypes.SIGN_IN_SUCCESS, value }),
  signOut: () => ({ type: ActionTypes.SIGN_OUT }),
  setRefreshToken: (value: SessionProps) => ({ type: ActionTypes.REFRESH_TOKEN, value }),
  performProfileInception: (value: ProfileProps) => ({ type: ActionTypes.PROFILE_ADDITION, value }),
};

export const getPermissions = (token: string | undefined) => {
  if (!token) return {};

  const { data } = JwtDecode(token) as { data: string };

  return data;
};

export const authComposer = async (url: string, payload: unknown) => {
  const { data } = await http.post(url, payload);

  return {
    ...data,
    ...getPermissions(data.token),
  };
};

export function* performAuthorizationLoader() {
  const token = yield state(({ auth }) => auth.token);

  if (token) dispatchToHttp(token);
}

export function* performSignIn({ value }: SagaCallEffect<SignInSuccessDataProps>) {
  try {
    const { refresh_token, ...payload } = yield call(authComposer, '/auth/client_user', { google_token: value.accessToken });

    yield all([
      put(actions.signInSuccess({ ...payload, refreshToken: refresh_token })),
      put(actions.performProfileInception(value.profile)),
    ]);
  } catch (exception) {
    toast.error('Falha na autenticação verifique seus dados');
    yield put(actions.signOut());
  }
}

export function* performAppSignIn({ value }: SagaCallEffect<SignInAppDataProps>) {
  try {
    const { refresh_token, ...payload } = yield call(authComposer, '/auth/client_app', value);

    yield put(actions.signInSuccess({ ...payload, refreshToken: refresh_token }));
  } catch (exception) {
    toast.error('Falha na autenticação verifique seus dados');
    yield put(actions.signOut());
  }
}

export function* performTenantLoader() {
  yield call(() => getTenants());
  yield call(() => getTradingUsers());
}

export function* performProfileRetrieve() {
  yield;
}

export function* saga() {
  yield takeEvery(REHYDRATE, performAuthorizationLoader);
  yield takeEvery(ActionTypes.SIGN_IN, performSignIn);
  yield takeEvery(ActionTypes.SIGN_IN_APP, performAppSignIn);
  yield takeEvery(ActionTypes.SIGN_IN_SUCCESS, performTenantLoader);
  yield takeEvery(ActionTypes.PROFILE_ADDITION, performProfileRetrieve);
}

export default reducer;
